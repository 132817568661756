import { white, black } from "./colors";

export const lightTheme = {
  theme: "light",
  backgroundColor: white[100],
  headerColor: black[100],
  textColor: black[200],
  codeColor: white[300],
  dividerColor: black[400],
  buttonBgColor: black[100],
  buttonColor: white[100]
};

export const darkTheme = {
  theme: "dark",
  backgroundColor: black[100],
  headerColor: white[100],
  textColor: white[200],
  codeColor: black[300],
  dividerColor: white[400],
  buttonBgColor: white[100],
  buttonColor: black[100]
};
