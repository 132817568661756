export const white = {
  100: "#FFF",
  200: "#828282",
  300: "#f8f8f8",
  400: "#272727"
};

export const black = {
  100: "#030303",
  200: "#999999",
  300: "#1e1e1e",
  400: "#e0e0e0"
};
